<template>
  <Dialog
    :visible="newLevel !== false"
    modal
    closable
    @update:visible="toggle"
    header=" "
    class="opacity-90"
  >
    <div class="flex flex-col items-center h-[70vh] text-center md:mx-10">
      <h1>{{ $t('vip-club.congrats_level_up') }}</h1>

      <CommonBadge
        size="md"
        :badge="newLevel.badge"
        class="animate-bounce mt-20"
      />
      <p class="mt-5 animate-pulse text-4xl font-bold">{{ newLevel.tier }}</p>
      <p class="mt-10 font-bold text-xl text-gray-400">
        {{ $t('vip-club.unlocked_benefits') }}:
      </p>
      <div class="flex mt-10 md:space-x-10">
        <div class="text-center md:bg-slate-900 p-5 rounded-xl">
          <Icon
            icon="fa-solid fa-hand-holding-dollar"
            class="text-3xl drop-shadow-[0_2.8px_6.2px_rgba(40,70,40.8)] text-slate-400 mb-2"
          ></Icon>
          <p class="text-gray-400">{{ $t('vip-club.level_up_bonus') }}</p>

          <p class="font-bold text-lg">
            {{ formatBigInteger(newLevel.level_up_bonus) }}
          </p>
        </div>
        <div class="text-center md:bg-slate-900 p-5 rounded-xl">
          <Icon
            icon="fa-solid fa-gift"
            class="text-3xl drop-shadow-[0_2.8px_6.2px_rgba(40,70,40.8)] text-slate-400 mb-2"
          ></Icon>
          <p class="text-gray-400">{{ $t('vip-club.free_rolls_per_day') }}</p>
          <p class="font-bold text-lg">
            {{ formatBigInteger(newLevel.rolls_per_day) }}
          </p>
        </div>
        <div class="text-center md:bg-slate-900 p-5 rounded-xl">
          <Icon
            icon="fa-solid fa-money-bill-transfer"
            class="text-3xl drop-shadow-[0_2.8px_6.2px_rgba(40,70,40.8)] text-slate-400 mb-2"
          ></Icon>
          <p class="text-gray-400">{{ $t('vip-club.reduced_commissions') }}</p>
          <p class="font-bold text-lg">
            {{
              newLevel.exchange_commission === 0
                ? $t('vip-club.free').toUpperCase()
                : newLevel.exchange_commission + '%'
            }}
          </p>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script setup>
const userStore = useUserStore();

const toggle = () => {
  userStore.levelUp(false);
};

const newLevel = computed(() => userStore.newLevel);
</script>
